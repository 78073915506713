.App {
    margin: 2rem;
}

h1 {
    color: #3f51b5;
}

.p-field {
    margin-bottom: 1rem;
}

.p-inputtext {
    width: 100%;
    max-width: 300px;
}

.p-button {
    margin-top: 1rem;
}

.my-form .p-field {
    display: flex;
    align-items: center;
}

.my-form .p-field label {
    margin-right: 1rem;
}

.my-form .p-inputtext {
    width: 100%;
}